import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import MenuButton from '../../../../MenuButton/viewer/skinComps/SeparateIndentedMenuButtonNSkin/SeparateIndentedMenuButtonNSkin';
import styles from './SeparateIndentedMenuButtonSkin.scss';

const SeparateIndentedMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <DropDownMenuBase {...props} styles={styles} Button={MenuButton} />;
};

export default SeparateIndentedMenuButtonSkin;
